



.mb_dec--50{
    margin-bottom: -50px;
}


.privacy-wrapper{
    margin-bottom: 50px;
}
