/*--------------------------
    Swicher Styles  
----------------------------*/
.active-light-mode .my_switcher ul li a.dark img {
    display: block;
}


.my_switcher ul li a.dark img {
    display: none;
    filter: brightness(100);
}



.active-light-mode .my_switcher ul li a.dark img {
    filter: brightness(0);
}

.my_switcher {
    border: 0 none;
    padding: 0;
    width: 40px;
    background: var(--color-secondary);
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    border-radius: 100%;
    border: 1px solid var(--color-border);
    transition: 0.4s;
    
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 100%;
        li {
            margin-top: 0;
            margin-bottom: 0;
            a {
                display: block;
                position: relative;
                z-index: 2;
                background: var(--color-secondary);
                width: 40px;
                height: 40px;
                border-radius: 100%;

                &::after {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: var(--background-color-1);
                    top: 0;
                    left: 0;
                    transform: scale(0.5);
                    opacity: 0;
                    border-radius: 100%;
                    transition: 0.3s;
                    z-index: -1;
                }
                &:hover {
                    border-color: transparent;
                    &::after {
                        transform: scale(1);
                        opacity: 1;
                    }
                }

                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    filter: brightness(0.5);
                    max-height: 16px;
                }
                &.active {
                    display: none;
                }
            }
        }
    }
    &:hover {
        border-color: transparent;
    }
}



.rn-slick-dot-style {
    .slick-dots {
        bottom: -11px;
    }
}




